import './business-group-icon.scss';

customElements.define('business-group-icon', class extends HTMLElement {
  constructor() {
    super();
    this._translations = translations.webComponents.businessGroupIcon;
    this._debugMode = false;
    this._ypos = 'bottom';
    this._xpos = 'center';
  }

  // static get observedAttributes() {
  //   return ['access'];
  // }

  // attributeChangedCallback(name, oldValue, newValue) {
  //   this._render();
  // }

  connectedCallback() {
    this._contentElement = document.createElement('div');
    this._contentElement.classList.add('business-group-icon-content');
    this._contentElement.setAttribute('data-ypos', this._ypos);
    this._contentElement.setAttribute('data-xpos', this._xpos);
    this._arrow = document.createElement('span');
    this._contentElement.insertAdjacentElement('beforeend', this._arrow);
    this._contentElement.insertAdjacentHTML('beforeend', decodeURIComponent(this._translations.createdForGroup));
    this.removeAttribute('title');
    document.body.insertAdjacentElement('beforeend', this._contentElement);

    this._render();
    this._addEvents();
  }

  disconnectedCallback() {
    document.body.removeChild(this._contentElement);
    this._translations = null;
    this._contentElement = null;
  }

  _addEvents() {
    this.addEventListener('mouseenter', e => {
      e.preventDefault();
      this._positionAt();
      this._contentElement.style.zIndex = this.zindex || 99;
      this._contentElement.style.opacity = 1;
    });

    this.addEventListener('mouseleave', _ => {
      this._contentElement.removeAttribute('style');
    });
  }

  get _isOwn() {
    return this.getAttribute('is-own') == '1';
  }

  get _isInGroup() {
    return this.getAttribute('is-in-group') == '1';
  }

  get _access() {
    return this.getAttribute('access') == '1';
  }

  /**
   * Positions the tooltip
   */
  _positionAt() {
    const dist = this.dist || 10;

    let parentCoords = this.getBoundingClientRect(), left, top;

    left = parseInt(parentCoords.left) + ((this.offsetWidth - this._contentElement.offsetWidth) / 2);
    top = parseInt(parentCoords.bottom) + dist;

    left = (left < 0) ? parseInt(parentCoords.left) : left;
    top = (top < 0) ? parseInt(parentCoords.bottom) + dist : top;

    this._contentElement.style.left = `${left}px`;
    this._contentElement.style.top = `${top + pageYOffset}px`;

    this._arrow.style.left = `${parentCoords.left + parseInt(parentCoords.width / 2) - left}px`;
  }

  _render() {
    this.title = '';
    this.setAttribute('access', this._isOwn || this._isInGroup ? 1 : 0);
    this.innerHTML = this._template;
  }

  get _template() {
    return `
      <b class="fas fa-lock${!!this._access ? `-open` : ``}"></b>
    `;
  }
});